import React from 'react';
import logoImage from '../assets/images/logo-white.png';
import {
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaMapMarkerAlt,
} from 'react-icons/fa';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';

export function Footer() {
  return (
    <footer className="bg-black text-white bottom-0 font-light ">
      <div className="flex flex-col-3 justify-around  h-[18rem] items-center ">
     
       {/* Logo */}
        <div className="">
          <Link to="/">
            <img
              className="w-[200px] py-4"
              src={logoImage}
              alt="pak business international logo"
            />
          </Link>

          {/* Address */}

          <div className="flex  items-center text-[#8f8f8f]">
            <FaMapMarkerAlt className="" />
            <p className="ml-2 ">
              Office # 14-B, 3rd floor, Crown Tower G-11/3, Islamabad, Pakistan
            </p>
          </div>

          {/* Phone Number */}
          <div className="flex items-center mt-5 text-[#8f8f8f]">
            <BsFillTelephoneFill className="" />
            <p className="ml-2 "> (+92)-51-2555654</p>
          </div>
          <div className="flex flex-row py-2 text-[20px]  mt-5">
            <FaFacebookF className=" hover:fill-[#66941E] mr-6 cursor-pointer transition-all duration-200 ease" />
            <FaLinkedinIn className="hover:fill-[#66941E] mr-6 cursor-pointer transition-all duration-200 ease" />
            <FaTwitter className="hover:fill-[#66941E] mr-6 cursor-pointer transition-all duration-200 ease" />
            <FaInstagram className="hover:fill-[#66941E] mr-6 cursor-pointer transition-all duration-200 ease" />
          </div>
        </div>

        {/* Overview */}
        <div className="flex flex-row justify-between ">
          <div className="mx-20">
            <p className="text-[#8f8f8f] text-md tracking-wide">Overview</p>
            <ul className="mt-2">
              <li className="p-1 cursor-pointer hover:text-[#66941E] transition-all duration-200 ease ">
                About Us
              </li>
              <li className="p-1 cursor-pointer hover:text-[#66941E] transition-all duration-200 ease ">
                Products
              </li>
              <li className="p-1 cursor-pointer hover:text-[#66941E] transition-all duration-200 ease">
                Contact Us
              </li>
              <li className="p-1 cursor-pointer hover:text-[#66941E] transition-all duration-200 ease">
                Careers
              </li>
            </ul>
          </div>

          {/* Services */}
          <div className="mx-20">
            <p className="text-[#8f8f8f] text-md tracking-wide">Services</p>
            <ul className="mt-2">
              <li className="p-1 cursor-pointer hover:text-[#66941E]  transition-all duration-200 ease ">
                Humanitarian Aid
              </li>
              <li className="p-1 cursor-pointer hover:text-[#66941E]  transition-all duration-200 ease">
                Logistics
              </li>
              <li className="p-1 cursor-pointer hover:text-[#66941E]  transition-all duration-200 ease">
                Export Imports
              </li>
              <li className="p-1 cursor-pointer hover:text-[#66941E]  transition-all duration-200 ease">
                Constructions
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Copyright */}
      <div className="bg-[#66941E] h-[2px]"></div>
      <p className="text-center text-xs py-4 tracking-wide bg-black text-white">
        Copyright © 2023 Pak Business Internationl. All rights reserved
      </p>
    </footer>
  );
}
