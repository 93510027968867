import React from 'react';
import { truckImages, earthMoving, powerSupply } from '../api/logistics';

export function Logistics() {
  return (
    <div>
      <div
        className="h-[30em] bg-cover w-full relative"
        style={{
          background:
            'linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.8)), url("https://cdn.pixabay.com/photo/2019/08/29/02/01/heavy-4437938_1280.jpg") no-repeat',
          backgroundSize: 'cover',
        }}>
        <div className="text-white text-center absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]  text-[3em] tracking-wider ml-10 font-thin">
          We are providing extraordinary{' '}
          <span className="text-[#66941E]">Logistics Solutions</span>{' '}
        </div>
      </div>

      {/* Comprehensive Background */}

      <div className="font-thin pt-20 ">
        <h1 className="text-[40px] text-center">Comprehensive Background</h1>
        <div className="bg-[#66941E] h-[1px] mx-[48%] mt-[2px] mb-[3em]"></div>
        <div className="flex   px-[12%]">
          <p className=" leading-9">
            We are a leading provider of integrated logistics, bringing
            efficiency to business and government supply chains in most
            challenging environments. The logistics solutions division of PBI is
            comprised by a group of professional from the field of logistics.
            The LS of PBI strongly believe in a partnership approach and
            provides personalized services to every client with a dedicated
            staff responding to the customized needs in a proactive manner.
            Using a proven and scalable process and drawing from our large
            network of people, we can combine our freight forwarding products,
            specialty businesses and logistics expertise to create solutions
            that accommodate the most dynamic and category-specific markets. Our
            experts are able to customize distinctive supply chain solutions for
            your specific business needs. As part of PBI LS Lead Logistics
            Concept, our experts consult with you to understand your business
            goals and challenges, finding more innovative and cost-effective
            ways to bring your goods to market. We take a high degree of
            personal responsibility to ensure your success, striving to reduce
            inventory and supply chain costs, while improving your overall
            productivity.
            <br />
            <br />
            <span className="text-[#66941E] text-[3em] pr-5 pt-5">
              {' '}
              Vision{' '}
            </span>
            Reach and deliver to the most challenging destinations
            <br />
            <br />
            <span className="text-[#66941E] text-[3em]  pr-5 pt-5">
              {' '}
              Mission{' '}
            </span>
            Meet your expectations through innovative, affordable, safe and
            efficient supply chain solutions. Road Freight Product Range
          </p>
        </div>

       

        {/* Road Frieght Product Range */}

        <div className="font-thin py-20 bg-gray-100 mt-20">
          <h1 className="text-[40px] text-center">
            Road Freight Product Range
          </h1>

          <div className="bg-[#66941E] h-[1px] mx-[48%] mt-[2px] mb-[3em] "></div>
          <div className="   px-[12%]">
            <p className="mt-20 leading-9">
              Road Freight Product Range PBI LS Road Freight products provide
              you with the right capacity allocation and equipment for every
              type of shipment. We have direct access to all prime capacities,
              including Full Truck Load (FTL) and Less than Truck Load (LTL)
              transports, as well as specialized equipment like refrigerated
              vehicles or double stacking trailers. With around-the-clock
              responsiveness for urgent inquiries, we deliver on-time
              performance you can depend on
            </p>
            <div className="flex justify-between items-end text-center mt-10">
              {truckImages.map((data, index) => {
                return (
                  <div className="w-[10%] group cursor-pointer ">
                    <img
                      className="w-full  group-hover:scale-[1.1] transition-all duration-500 ease"
                      src={require(`../assets/images/logistics/${data.image}`)}
                      alt={data.alt}
                    />
                    <p className="mt-5">{data.name}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {/* Earthmoving Machinery */}
        <div
          className="bg-cover bg-center py-40 text-white"
          style={{
            background:
              'linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.8)), url("https://cdn.pixabay.com/photo/2017/04/02/09/08/bulldozer-2195329_1280.jpg") no-repeat',
            backgroundSize: 'cover',
          }}>
          <h1 className="text-[40px] text-center">
            Range of Earthmoving Machinery
          </h1>

          <div className="bg-[#66941E] h-[1px] mx-[48%] mt-[2px] mb-[3em]"></div>
          <div className="   px-[12%] ">
            <p className="mt-20 leading-9">
              PBI LS has a wide range of new specialized and earth moving
              machinery. The new machinery ensures the speed and efficiency of
              construction work to progress without a breakdown. The machinery
              can be moved from one site to another with a fast speed. All the
              staff operating the machinery is provided with protective
              clothing, accident insurance is provided to cover any loss or
              injury in case of an accident. (Insurance is optional if required
              by the client).
            </p>
            <div className="flex justify-between items-end text-center mt-10">
              {earthMoving.map((data, index) => {
                return (
                  <div className="w-[10%] group cursor-pointer">
                    <img
                      className="w-full group-hover:scale-[1.1] transition-all duration-500 ease "
                      src={require(`../assets/images/logistics/${data.image}`)}
                      alt={data.alt}
                    />
                    <p className="mt-5">{data.name}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

 {/* Power Supply*/}
 <div className="py-20">
          <h1 className="text-[40px] text-center">
            Power Supply Standby & Prime
          </h1>
          <div className="bg-[#66941E] h-[1px] mx-[48%] mt-[2px] mb-[3em]"></div>

          <div className="   px-[12%] ">
            <p className="mt-20 leading-9">
              PBI LS provided generator for prime and standby supply of
              electricity our stock of generator are brand new and mainly
              consist of world popular brands CAT and Perkins. The generators
              are maintained and repaired by the company well trained staff to
              ensure the continuity of service a separate log is maintained for
              each set.
            </p>
            <div className="flex justify-around items-end text-center mt-10 ">
              {powerSupply.map((data, index) => {
                return (
                  <div className="w-[30%] group cursor-pointer">
                    <img
                      className="w-full group-hover:scale-[1.1] transition-all duration-500 ease "
                      src={require(`../assets/images/logistics/${data.image}`)}
                      alt={data.alt}
                    />
                    <p className="mt-5">{data.name}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>





      </div>
    </div>
  );
}
