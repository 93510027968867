import React, { useState } from 'react';
import logoImage from '../assets/images/logo-black.png';
import { Link } from 'react-router-dom';
import { navLink } from '../api/navlink.api';

export default function Header(props) {
  const [navState, setNavState] = useState(navLink);

  function handleNavClick(lPath) {

 setNavState(navState.map((data)=>
 {
    if(data.linkPath===lPath){
      return{...data, isActive: true}
    }
    else{
      return{...data, isActive: false}
    }
  
  }))


  }

  return (
    <div className="flex justify-space-between items-center py-3 bg-gray-100 sticky top-0 w-full z-10  shadow-md ">
      <Link to="/" className="l-10 ml-5 ">
        <img
          src={logoImage}
          alt="pak business international logo"
          width={170}
          height={50}
        />
      </Link>

      <div className=" mr-36  ml-auto flex">
        {navState.map((data, index) => {
          return (
            <div className="relative group"   key={index}>
              <Link
              
                className={`px-5 py-8 mx-2  ${
                  data.isActive ? 'bg-black text-white' : 'text-black'
                } group-hover:bg-black group-hover:text-white relative group  hover:font-thin font-thin`}
                to={data.linkPath}
                onClick={() => handleNavClick(data.linkPath)}>
                <span className="bg-[#66941E] absolute w-[50%] transition-all duration-500 ease h-[1px] left-1/2 translate-x-[-50%] group-hover:w-[0%] top-[25px] z-50"></span>
                {data.name}
                <span className="bg-[#66941E] w-[0%] absolute group-hover:w-[100%] transition-all duration-500 ease h-[2px] left-0 -bottom-[1px] z-50"></span>
              </Link>
              <ul
                className={`hidden ${data.subLink.length > 0?'group-hover:block':''} absolute top-0 left-2 bg-slate-50 translate-y-[52px] font-thin w-[10em] h-auto border overflow-hidden`}>
                {data.subLink.length > 0 &&
                
                  data.subLink.map((subData, subIndex) => {
                 
                    return (
                      <Link to={`/${(data.name).toLowerCase()}/`+subData.path}>
                      <li
                        className="cursor-pointer hover:bg-[#405224] hover:text-white px-2 py-2 border">
                        {subData.name}
                      </li>
                      </Link>
                    );
                  })}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
}
