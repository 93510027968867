import React, { useEffect, useState } from 'react';
import './App.css';
import Header from './components/Header';
import { TopNav } from './components/TopNav';
import { ContactUs } from './components/ContactUs';
import { Footer } from './components/Footer';
import { Home } from './components/Home';
import { AboutUs } from './components/AboutUs';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ErrorPage } from './components/ErrorPage';
import { Careers } from './components/Careers';
import { Logistics } from './components/Logistics';
import ScrollToTop from './components/ScrollToTop';
import ImportExport from './components/ImportExport';
import { Constructions } from './components/Constructions.js';
import { HumanAid } from './components/HumanAid';
import { request } from 'graphql-request';
import ProductList from './components/ProductList';
import { ProductDetails } from './components/ProductDetails';

const graphcms =
  'https://eu-west-2.cdn.hygraph.com/content/clcovlezn0h2v01uiar1j9y5g/master';
const QUERY = `{
  products ( first:100 ) {
        id
      productName
    category {
      name
      slug
      
    }
    isFeatured
    standard
    {
      id
      standardType
    }
    material
    productImage {
      url
    }
    slugProductName
  

    specification {
      html
    }
    productDescr
  }
  
  categories {
    name
    slug
  }
  
}`;

function App() {
  const [product, setProduct] = useState([]);

  const fetchProducts = async () => {
    const {products, categories } = await request(graphcms, QUERY);
    setProduct({ products, categories });

  };

  useEffect(() => {
    fetchProducts();

  }, []);

  return (
    <Router>
      <div className="App">
        <ScrollToTop>
          <TopNav />

          <Header productData={product} />
          <Routes>
            <Route path="/" element={<Home productData={product} />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route
              path="/products/:category"
              element={<ProductList productData={product} />}
            />
            <Route
              path="/products/product-details/:productCategory/:productSlug/:productId"
              element={<ProductDetails />}
            />
            <Route path="/contact/office" element={<ContactUs />} />
            <Route path="/contact/careers" element={<Careers />} />
            <Route path="/services/human-aid" element={<HumanAid />} />
            <Route path="/services/logistics" element={<Logistics />} />
            <Route path="/services/construction" element={<Constructions />} />
            <Route path="/services/import-export" element={<ImportExport />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>

          <Footer />
        </ScrollToTop>
      </div>
    </Router>
  );
}

export default App;
