export const navLink = [
  { id: 0, isActive: true, linkPath: '/', name: 'Home', subLink: [] },
  {
    id: 1,
    isActive: false,
    linkPath: '/aboutus',
    name: 'About Us',
    subLink: [],
  },
  {
    id: 2,
    isActive: false,
    linkPath: '/products/all',
    name: 'Products',
    subLink: [
      { name: 'Core Relief', path: 'core-relief' },
      { name: 'Family Tents', path: 'family-tents' },
      { name: 'Multipurpose Tents', path: 'multi-purpose' },
      { name: 'Non Standard Items', path: 'non-standard' },
      { name: 'Food Items', path: 'food-items' },
    ],
  },
  {
    id: 3,
    isActive: false,
    linkPath: '/services/human-aid',
    name: 'Services',
    subLink: [
      { name: 'Humanitarian Aid', path: 'human-aid' },
      { name: 'Logistics', path: 'logistics' },
      { name: 'Import Export', path: 'import-export' },
      { name: 'Construction', path: 'construction' },
    ],
  },
  {
    id: 3,
    isActive: false,
    linkPath: '/contact/office',
    name: 'Contact',
    subLink: [
      { name: 'Our Office', path: 'office' },
      { name: 'Careers', path: 'careers' },
    ],
  },
];
