import React from 'react';
import { Link } from 'react-router-dom';
import { Product } from './Product';

export function HumanAid() {
  return (
    <div className="font-thin">
      <div
        className="h-[30em] w-full relative"
        style={{
          background:
            'linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.8)), url("https://cdn.pixabay.com/photo/2017/10/22/00/32/children-2876359_1280.jpg") no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}>
        <div className="text-white text-center absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]  text-[3em] tracking-wider ml-10 font-thin">
          Working in Humanitarian Aid sector{' '}
          <span className="text-[#66941E] font-thin">Since 2007</span>{' '}
        </div>
      </div>
      <div className="font-thin ">
        <h1 className="text-[40px] text-center mt-10">Our Story</h1>
        <div className="bg-[#66941E] h-[1px] mx-[48%] mt-[2px] mb-[3em]"></div>
      </div>

      <div className="leading-9 mx-[10%]">
        "Pak Business International was formed by a group qualified and
        professionals from various business sectors. The company was registered
        in 2007 with government of Pakistan Federal Board of Revenue. Since its
        establishment of company has focused on the provision of goods and
        services to various commercial and non-commercial clients in Pakistan.
        PBI recently established two offices in Dubai and Afghanistan with a
        long term vision to provide regional business solutions to facilitate
        its clients in the flow of goods and services from and to the demanding
        market of the area. PBI believes in the provision of quality goods and
        services with commitment of delivering to the right place at right time
        with affordable price. The company strongly believes in customer
        orientation and attaches high value to the overall satisfaction of its
        clients. The company has worked extensively in relief and rehabilitation
        sector with a number of international organizations and has proven
        strengths in delivering quality products and services all over Pakistan"
      </div>

      <Link to="/products/all">
        <input
          className="  cursor-pointer p-2 rounded-2xl text-center my-10 mb-20 mx-auto bg-[#bfcd2a] block hover:scale-[1.1] transition-all duration-500 ease hover:bg-[#97a122]"
          value="Learn more..."></input>
      </Link>
    </div>
  );
}
