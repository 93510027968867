import React, { useState } from 'react';
import productImage from '../assets/images/products/soft-blanket.png';
import { FaChevronDown } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

export function ProductDetails() {
  const { state } = useLocation();
  const [loadImage, setLoadImage] = useState(state.data.productImage[0].url);

  return (
    <>
      <div className="bg-black h-[20em] w-full relative">
        <span className="absolute text-white font-thin text-[3em] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 tracking-wider">
          Product Details
        </span>
      </div>

      <div className="flex mx-[10%] py-20 justify-around mt-20">
        <div className="w-[40%] ">
          <div className='h-[23em] w-[23em]'>
            <div className="bg-gray-100 rounded-md h-full w-full flex flex-col justify-center items-center overflow-hidden">
              <img
                className="w-full h-auto my-auto"
                src={loadImage}
                alt="product image"
              />
            </div>
          </div>

          <div className="flex  justify-left">
            {state.data.productImage.map((data) => {
              return (
                <div className="flex mt-5">
                  <div
                    className={`w-[5.5em] h-[5.5em] bg-gray-100 border-[1px] ${
                      loadImage === data.url
                        ? 'border-[#85C226]'
                        : 'border-none'
                    } rounded-lg  mr-[2em] cursor-pointer flex flex-col justify-center overflow-hidden`}
                    onClick={() => setLoadImage(data.url)}>
                    <img
                      className="w-full h-auto "
                      src={`${data.url}`}
                      alt="product image"
                      height="200px "
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className=" w-[60%] px-[10%]  font-thin">
          <h1 className="text-[1.5em] font-semibold mb-5 tracking-[0.1em] uppercase bg-[#405224] py-2 px-2 text-white">
            {state.data.productName}
          </h1>
          <div className="mt-5">
            <div className=" bg-[] pt-2 border-b border-[#85C226]">
              <h1 className="text-[1.3em] font-light flex items-center  tracking-wider text-[#405224]">
                Standard
              </h1>
            </div>
            <p className="py-2 bg-gray-50 pl-2">
              {state.data.standard.standardType}
            </p>
          </div>
          <div className="mt-5">
            <div className=" bg-[] pt-2 border-b border-[#85C226]">
              <h1 className="text-[1.3em] font-light flex items-center  tracking-wider text-[#405224]">
                Material
              </h1>
            </div>
            <p className="py-2 bg-gray-50 pl-2">{state.data.material}</p>
          </div>

          <div className="mt-5">
            <div className=" bg-[] pt-2 border-b border-[#85C226]">
              <h1 className="text-[1.3em] font-light flex items-center  tracking-wider text-[#405224]">
                Description
              </h1>
            </div>
            <p className="py-2 bg-gray-50 pl-2">{state.data.productDescr}</p>
          </div>
        </div>
      </div>

      <div className="py-20">
        <div className=" mx-[10%] mb-5">
          <h1 className="pt-2 border-b border-[#85C226] text-[1.3em] font-light flex items-center  tracking-wider text-[#405224]">
            Specification
          </h1>
        </div>
        <div className="font-thin">
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: state.data.specification.html,
            }}></div>
        </div>
      </div>
    </>
  );
}
