import React from 'react';
import { Link } from 'react-router-dom';
import heroImage from '../assets/images/hero-pk.png';

export function Hero() {
  return (
    <div className="bg-blend-overlay">


      <div
        className="h-[50rem] w-full relative"
        style={{
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${heroImage})`,
        }}>
        <h1 className="text-white font-bold text-6xl w-full px-[10rem] text-center tracking-wider leading-[4rem] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          Proud to be a part of ecosystem <span className='text-[#66941E]'>serving humanity</span> 
        </h1>
        <Link to='/services/human-aid'>
        <button className="bg-white tracking-wider text-black rounded-[20px] px-10 py-2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-[10rem] hover:bg-gray-200 hover:scale-[1.1] transition-all duration-500 ease">
          Learn More
        </button>
        </Link>
      </div>
    </div>
  );
}
