import React from 'react';
import { Link } from 'react-router-dom';
import {
  constructionPath,
  exportImportPath1,
  exportImportPath2,
  exportImportPath3,
  humanAidPath1,
  humanAidPath2,
  logisticsPath,
} from '../assets/images/svg';


export const Services = () => {
  return (
    <div className="flex justify-center text-[#545454] text-center tracking-widest w-full  mt-[0] ">
      <div className="bg-white w-[30%]  px-10 py-[15em] shadow-sm">
        <h1 className="text-center text-[#5a5a5a] text-[2.5em] font-light mb-[15px]">
          We have built our legacy in
        </h1>
        <div className="bg-[#66941E] h-[1px] mx-[42%] "></div>
      </div>
      <div className="flex w-[70%] justify-center flex-wrap bg-gray-50 py-[15em] shadow-sm">
      <Link to={'/services/human-aid'}>
        <div className="group flex flex-col items-center cursor-pointer h-[7rem] my-5 mx-12 hover:text-[#66941E] justify-between">
          <svg
            className="fill-[#405224] h-[8rem] cursor-pointer group-hover:scale-[1.2] translate-all duration-500 ease-in-out w-[7rem]"
            viewBox="0 0 1000 1000">
            <path d={humanAidPath1} />
            <path d={humanAidPath2} />
          </svg>
          <p className="font-light text-[1.5rem] mt-5 text-[#818181]  group-hover:text-[#545454]">
            Humanitarian Aid
          </p>
        </div>
        </Link>

        <Link to={'/services/logistics'}>
          <div className="group flex flex-col items-center cursor-pointer h-[7rem] my-5 mx-12 hover:text-[#66941E] justify-between">
            <svg
              className="fill-[#405224] cursor-pointer group-hover:scale-[1.2] translate-all duration-500 ease-in-out w-[7rem]"
              viewBox="0 0 1000 1000">
              <path d={logisticsPath} />
            </svg>
            <p className="font-light text-[1.5rem] mt-5 text-[#818181]   group-hover:text-[#545454] ">
              Logistics
            </p>
          </div>
        </Link>

        <Link to={'/services/import-export'}>
        <div className="group flex flex-col items-center cursor-pointer h-[7rem] my-5 mx-12 hover:text-[#66941E] justify-between">
          <svg
            className="fill-[#405224]  cursor-pointer group-hover:scale-[1.2] translate-all duration-500 ease-in-out w-[7rem]"
            viewBox="0 0 1160 918">
            <path d={exportImportPath1 + exportImportPath2} />
            <path d={exportImportPath3} />
          </svg>
          <p className="font-light text-[1.5rem] mt-5 text-[#818181]  group-hover:text-[#545454] ">
          Imports Exports
          </p>
        </div>
        </Link>
        <Link to={'/services/construction'}>
        <div className="group flex flex-col items-center cursor-pointer  h-[7rem] my-5 mx-12 justify-between">
          <svg
            className="fill-[#405224]  group-hover:scale-[1.2] translate-all duration-500 ease-in-out  w-[5rem]"
            viewBox="0 0 116.36 122.88">
            <path d={constructionPath} />
          </svg>
          <p className="font-light text-[1.5rem] mt-5 text-[#818181] align-bottom  group-hover:text-[#545454]">
            Constructions
          </p>
        </div>
        </Link>
      </div>
    </div>
  );
};
