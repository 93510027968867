import React from 'react';
import { products } from '../api/import.export.api';

export default function ImportExport() {
  return (
    <div className="">
      {/* Cover for Import Export */}
      <div
        className="h-[30em] bg-center relative"
        style={{
          background:
            'linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.7)),url("https://cdn.pixabay.com/photo/2015/01/11/10/28/container-ship-596083_1280.jpg") center',
          backgroundSize: 'cover',
          backgroundPosition: 'bottom',
        }}>
        <div className="text-white text-center absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]  text-[3em] tracking-wider ml-10 font-thin">
          Our experience in{' '}
          <span className="text-[#66941E]">Import/Exports</span>{' '}
        </div>
      </div>

      {/* Briefing */}

      <div className="">
        <div className="text-center  text-[2em] py-5 font-thin mt-10">Insight</div>
        <div className="bg-[#66941E] h-[1px]  mx-[48%] mt-[-15px] mb-10"></div>

        <p className="px-[10%] font-thin leading-9">
          Pak Business is in business of imports and exports since 2012. In this
          time period Pak Business maintained business terms across the borders
          and supplied variety of products. These are some line of products that
          Pak Business have imported and supplied to fulfill its vendours
          demand.
        </p>

        <div className="flex justify-between items-end text-center mt-20 mx-[15%] flex-wrap">
          {products.map((data, index) => {
            return (
              <div className=" group cursor-pointer p-5">
                <img
                  key={index}
                  className="w-[8em] mx-auto group-hover:scale-[1.1] transition-all duration-500 ease "
                  src={require(`../assets/images/import-exports/${data.image}`)}
                  alt={data.alt}
                />
                <p className="mt-5" key={index + 'Name'}>
                  {data.name}
                </p>
              </div>
            );
          })}
        </div>

        <div className=" px-[15%] bg-gray-100 flex mt-20 font-thin mb-[-20px]" >
          <p className='w-[50%] leading-9 my-auto'>
            Pak Business was also a part of a massive project for supplying
            hundreds of thousands of <span className='text-[#66941E] font-light'>NEWBORN BABY KITS</span> as defined by Unicef
            standard. We had partnered up with a company to deliver supply to
            Unicef in foriegn borders (Afghanistan){' '}
          </p>
          <img
            src={require('../assets/images/import-exports/baby-kit.png')}
            alt="baby kit unicef standard"
          />
        </div>
      </div>
    </div>
  );
}
