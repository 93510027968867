import React from 'react';

export function Careers() {
  return (
    <>
      <div
        className="bg-cover bg-center w-full h-[30em] relative "
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("https://cdn.pixabay.com/photo/2015/01/08/18/25/desk-593327_1280.jpg")`,
        }}>
        <div className="text-white absolute top-[50%] left-1/2 translate-x-[-50%] translate-y-[-50%]  text-[3em] tracking-wider ml-10 font-thin">
          Join our <span className="text-[#66941E]">team</span>{' '}
        </div>
      </div>

      <div className=" font-thin">
        <p className="text-[#5a5a5a] h-[15em] py-10 mx-[10%] ">
          Please send your CV to{' '}
          <span className="text-[#66941E] py-10">pakbusiness.int@gmail.com</span>.
        </p>
        <hr className="w-full" />
        <div className="text-[#5a5a5a] py-10 mx-[10%] ">
          Kindly <span className='text-red-500 font-semibold '>note</span> that only selected applicants will be contacted.
        </div>
      </div>
    </>
  );
}
