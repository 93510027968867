import React, { useRef } from 'react';
import { MdEmail } from 'react-icons/md';
import { IoIosCall } from 'react-icons/io';
import { HiLocationMarker } from 'react-icons/hi';
import emailjs from '@emailjs/browser';



export function ContactUs() {
  const form = useRef();

  const sendMessage = (e) => {
    e.preventDefault()
    
    emailjs.sendForm('service_xmb4hxb', 'template_way5u3e', form.current, 'bgiM8JM6yHfTmHTcX')
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });
    
    }


  return (
    <>
      <div
        className="bg-cover  w-full h-[30em] relative overflow-hidden"
        style={{
          background:
            'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),url("https://cdn.pixabay.com/photo/2018/01/17/04/14/businessman-3087395_1280.jpg") no-repeat',
            backgroundSize: 'cover',
            width:'100%',
        }}>
        <div className="text-white absolute top-[50%] left-1/2 translate-x-[-50%] translate-y-[-50%]  text-[3em] tracking-wider ml-10 font-thin text-center">
          Get a detailed <span className="text-[#66941E]">insight</span> on our
          process{' '}
        </div>
      </div>

      <div className="text-center  w-full flex justify-center mt-[5em]  font-light">
        <div className="bg-white w-[30%] mx-10  ">
          <h1 className="text-[3em] font-lighter text-[#5a5a5a]">Get in touch with us</h1>
          <div className="flex align-middle py-6 ml-10">
            <IoIosCall className="text-[#66941E] text-[2em]" />
            <p className="ml-5 text-left text-[#5a5a5a]">(+92)-51-2555654</p>
          </div>
          <hr />
          <div className="flex align-middle py-6 ml-10">
            <MdEmail className="text-[#66941E] text-[2em]" />
            <p className="ml-5 text-left text-[#5a5a5a]">
              info@pakbusiness.com.pk
            </p>
          </div>
          <hr />
          <div className="flex align-middle py-6 ml-10">
            <HiLocationMarker className="text-[#66941E] text-[2.5em]" />
            <p className="ml-5 text-left text-[#5a5a5a]">
              Crown Towers, 3rd Floor, Unit 14-B, Street 91, G-11/3 Islamabad,
              44000
            </p>
          </div>
        </div>

        <div className="w-[50%] bg-white ml-20 text-center">
          <h1 className=" mb-5 text-[#66941E] text-xl py-3 text-left font-thin">
            How can we help you ?
          </h1>
          <form
            className="mb-[10em] text-left"
            id="contact"
            ref={form}
            onSubmit={sendMessage}>
            <div className="w-[80%]">
              <span className="flex flex-col">
                <label className="  text-[#5a5a5a] text-sm " htmlFor="name">
                  Your Name <span className="text-[#66941E]">*</span>
                </label>
                <div className="flex justify-between ">
                  <input
                    className="px-5 w-full  py-[4px] mr-1 border-b-[1px] text-[#5a5a5a]"
                    id="name"
                    type="text"
                    name='first_name'
                    placeholder="First Name"
                  />

                  <input
                    className="px-5 w-full py-[4px] mr-1 border-b-[1px] text-[#5a5a5a]"
                    id="name"
                    type="text"
                    name='last_name'
                    placeholder="Last Name"
                  />
                </div>
              </span>
              <span className=" flex flex-col my-4 ml-auto">
                <label className=" text-[#5a5a5a] text-sm" htmlFor="cell">
                  Phone no <span className="text-[#66941E]">*</span>
                </label>
                <input
                  className="px-5 py-[4px] mr-1 border-b-[1px] text-[#5a5a5a]"
                  id="cell"
                  type="text"
                  name='phone'
                  placeholder="+(xxx)-xxxxxxxx"
                />
              </span>

              <div className="flex flex-col my-4 ml-auto">
                <label className="text-[#5a5a5a] text-sm" htmlFor="email">
                  Email <span className="text-[#66941E]">*</span>
                </label>
                <input
                  className="px-5 py-[4px] mr-1 border-b-[1px] text-[#5a5a5a]"
                  id="email"
                  type="text"
                  name='email'
                  placeholder="example@email.com"
                />
              </div>

              <div className="block my-5 ml-auto">
                <label className="text-[#5a5a5a] text-sm" htmlFor="query">
                  Enter your message:
                </label>
                <textarea
                  className="px-5 bg-gray-50  mr-1 block border w-[100%] h-[300px] resize-none text-[#5a5a5a] rounded-sm"
                  id="query"
                  name="query"
                  form="contact"></textarea>
              </div>

              <input
                className="bg-black text-white px-9 py-1 rounded-md cursor-pointer mt-5 "
                type="submit"
                value={'Send'}
              />
            </div>
          </form>
        </div>
      </div>
      <iframe
        className=" mb-0"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13280.530137284779!2d72.98147619884621!3d33.67963224486773!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfbfc3b2e5f5ed%3A0x3a835c924a2d2deb!2sPak%20Business%20International!5e0!3m2!1sen!2s!4v1673975729725!5m2!1sen!2s"
        height="450"
        width="100%"
 
        loading="lazy"></iframe>
    </>
  );
}
