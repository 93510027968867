import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Product } from './Product';

export function Featured(props) {
  const [featured, setFeatured] = useState([]);

  useEffect(() => {
    const featuredProduct = props?.data?.products?.filter(
      (d) => d.isFeatured === true
    );
    setFeatured(featuredProduct);
  }, [props]);

  return (
    <div className="bg-gradient-to-r  from-[#66941E] to-[#92dff4] py-[4em] ">
      <div className="text-center text-white text-[2em] py-5 font-thin">
        We have sorted some of our best selling products for you
      </div>
      <div className="bg-white h-[1px]  mx-[48%] mt-[-15px] mb-10 "></div>

      <Link to="/products/all">
        {' '}
        <div className="overflow-x-scroll">
          <div className="flex  justify-center">
            {featured && featured.map((data) => <Product data={data} />)}
          </div>
        </div>
        <div className="text-center font-light text-white text-[1.5em] py-5 cursor-pointer tracking-widest animate-pulse ">
          learn more...
        </div>
      </Link>
    </div>
  );
}
