import React from 'react';
import modelVilla from '../assets/images/construction/model-villa.jpeg';

export function Constructions() {
  return (
    <div>
      <div
        className="h-[30em] bg-cover w-full relative"
        style={{
          background:
            'linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.8)), url("https://cdn.pixabay.com/photo/2019/08/29/02/01/heavy-4437938_1280.jpg") no-repeat',
          backgroundSize: 'cover',
        }}>
        <div className="text-white absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]  text-[3em] tracking-wider ml-10 font-thin">
          Our work in <span className="text-[#66941E]">Construction</span>{' '}
          sector
        </div>
      </div>

      <div className="py-20">
        <div className="font-thin ">
          <h1 className="text-[40px] text-center ">Breif Outline</h1>
          <div className="bg-[#66941E] h-[1px] mx-[48%] mt-[2px] mb-[3em]"></div>
        </div>

        <div className="px-[10%] font-thin flex">
          <p className="p-10 leading-9">
            Collaboration with <strong>IIC</strong> Pak Business has managed to
            develop more than 200 Model Villas in collaboration with United
            Nations in Afghanistan. <br/>
            Pak Business has also remained involved in construction of some commercial establishments in Islamabad
          </p>

          <div className='p-10'>
            <img
              className="grayscale rounded-xl"
              src={modelVilla}
              alt="model villas by pak business international"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
