import React from 'react';

import { partners } from '../api/partners.api';

export function Partners() {
  return (
    <div className="text-center bg-black py-10">
      <div className="text-center text-white text-[2em] py-5 font-thin">
        Organizations we have grown with
      </div>
      <div className="bg-white h-[1px]  mx-[48%] mt-[-15px]"></div>

      <div className=" w-full mt-10 px-[10%] flex flex-row flex-wrap justify-center">
        {partners.map((data, key) => (
          <div className=" text-center  w-[110px] h-[110px] p-[12px] mx-[30px] my-[30px]  bg-white rounded-lg shadow-md flex flex-col justify-center cursor-pointer group">
            <img
              className="p-1.5 group-hover:scale-[1.2] transition-all duration-300 ease-in-out"
              src={require(`../assets/images/partners/${data.partnerName}.png`)}
              alt={data.partnerAlt}
              key={(key).toString()}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
