import React from 'react';
import ceoImage from '../assets/images/ceo-wbg.png';

import { GiFlowerEmblem } from 'react-icons/gi';
import { FaQuoteLeft } from 'react-icons/fa';

export const AboutUs = () => {
  return (
    <div>
      <div
        className="bg-cover bg-center h-[30em] relative overflow-hidden"
        style={{
          background:
            'linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),url(" https://cdn.pixabay.com/photo/2015/01/08/18/11/laptops-593296_1280.jpg") no-repeat',
          backgroundSize: 'cover',
          width: '100%',
        }}>
        <div className="text-white absolute top-[50%] left-1/2 translate-x-[-50%] translate-y-[-50%]  text-[3em] tracking-wider ml-10 font-thin text-center ">
          Want to know about our{' '}
          <span className="text-[#66941E]"> Journey</span>
        </div>
      </div>

      {/* How we Started */}
      <div className=" flex">
        <div className="font-thin w-[65%]">
          <h1 className="text-[40px] text-center text-[#5a5a5a] pt-20">
            How we started
          </h1>

          <div className="bg-[#66941E] h-[1px] mx-[48%] mt-[2px] "></div>

          <p className="px-[12%] py-10 my-5 leading-9 tracking-normal text-[#5a5a5a] text-justify text-[1em] col-span-2">
            "Pak Business International was formed by a group qualified and
            professionals from various business sectors. The company was
            registered in 2007 with government of Pakistan Federal Board of
            Revenue. Since its establishment of company has focused on the
            provision of goods and services to various commercial and
            non-commercial clients in Pakistan. PBI recently established two
            offices in Dubai and Afghanistan with a long term vision to provide
            regional business solutions to facilitate its clients in the flow of
            goods and services from and to the demanding market of the area. PBI
            believes in the provision of quality goods and services with
            commitment of delivering to the right place at right time with
            affordable price.
            <br />
            The company strongly believes in customer orientation and attaches
            high value to the overall satisfaction of its clients. The company
            has worked extensively in relief and rehabilitation sector with a
            number of international organizations and has proven strengths in
            delivering quality products and services all over Pakistan"
          </p>
        </div>

        <div className="bg-[#405224] font-thin w-[35%] flex flex-col justify-center text-center ">
          <GiFlowerEmblem className="text-[15em] text-white w-full " />
        </div>
      </div>

      {/* Vision and Mission */}

      <div className="flex py-40 bg-black text-white font-thin ">
        {/* Vision */}
        <div className="w-[50%] border-r-[1px] border-[#66941E]">
          <h1 className="text-[40px] text-center  mt-20 font-light">
            Our Vision
          </h1>
          <div className="bg-white h-[1px] mx-[48%] mt-[2px]"></div>
          <p className="px-[20%] py-10 my-5 leading-9 tracking-normal  text-justify text-[1em] ">
            We want to make it simple to deliver anything, anywhere at
            affordable price and quality develop trust in market and
            organizations working in the relief and rehabilitation sector.{' '}
          </p>
        </div>
        {/* Mission */}

        <div className=" w-[50%]">
          <h1 className="text-[40px] text-center  mt-20 font-light">
            Our Mission
          </h1>
          <div className="bg-white h-[1px] mx-[48%] mt-[2px]"></div>
          <p className="px-[20%] py-10 my-5 leading-9 tracking-normal  text-justify text-[1em] ">
            We are committed to supply quality products in complete satisfaction
            of client organizations meeting the required quality levels at
            reasonable cost in an efficient manner and remove this market gap
            between market and company.
          </p>
        </div>
      </div>
      {/* CEO Message */}
      <div className="py-20 ">
        <h1 className="text-[40px] text-center  text-[#5a5a5a]  font-thin">
          CEO's Message
        </h1>
        <div className="bg-[#66941E] h-[1px] mt-[2px] relative mx-[48%]"></div>

        <div className="flex justify-center w-full group mt-[13em]">
          <div className="w-[50%] text-center bg-gray-50 ">
            <img
              className=" w-[400px] grayscale mx-auto"
              src={ceoImage}
              alt="CEO Pak Business"
            />

            <h1 className="text-[1.5em] py-2 text-white tracking-wider font-thin bg-[#5a5a5a]">
              Humayun Khattak <span className=" text-[#b3e16a]">(CEO)</span>
            </h1>
          </div>

          {/* Separater inside CEO Message and Image */}
          <div className="w-[3px] bg-white"></div>
          <p className=" relative text-left px-[4em] py-20 w-[50%]   leading-[50px] tracking-[4px] bg-gray-50 text-[#5a5a5a] text-[1.5em] font-thin">
            We are committed to supply quality products in complete satisfaction
            of client organizations meeting the required quality levels at
            reasonable cost in an efficient manner and develop trust in market
            and organizations working in the relief and rehabilitation sector.
            <span className="absolute left-0 top-0 -translate-y-[85px] translate-x-[10px] text-[4.5em] text-[#405224]">
              <FaQuoteLeft />
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
