import React from 'react';
import { Services } from './ServicesHome';
import { Hero } from './HeroHome';
import { Partners } from '../components/Partners';
import { Process } from './ProcessHome';
import { Values } from './ValuesHome';
import { Featured } from './Featured';

export function Home(props) {
  return (
    <>
      <Hero />
      <Services />
      <Process />
      <Partners />
      <Values />
      <Featured data={props.productData} />


    </>
  );
}
