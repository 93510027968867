import React, { useState } from 'react';
import { GrView } from 'react-icons/gr';


export function Product(props) {
  return (
    <div className="">
    

            <div className=" w-[230px] h-[320px] text-left cursor-pointer mx-10 shadow-md relative group rounded-[20px]  my-10 bg-black group overflow-hidden"
            >
              <div className="relative overflow-hidden h-[220px] bg-gray-100 rounded-t-[20px] flex flex-col justify-center ">
                <GrView
                  className="absolute bottom-0 left-0 right-0 -translate-y-[100%]  m-auto text-[4em] hidden group-hover:block  z-40 "
                  color="gray"
                  />

                <img
                  className=" h-auto rounded-tl-[20px] overflow-hidden rounded-tr-[20px] group-hover:scale-[1.1] transition-all duration-300 ease group-hover:opacity-70"
                  src={props.data.productImage[0].url}
                  alt={props.data.productName}
                  />
              </div>

              <div className='overflow-ellipsis w-[100%]'>
          

                <p className=" ml-3 pt-5 font-semibold text-[.9em] text-white tracking-wider text-left relative uppercase mx-5 whitespace-nowrap">
                  {props.data.productName}
                  <span className="bg-[#66941E]  absolute w-[100%] transition-all duration-500 ease h-[3px] left-0 -bottom-[1px]"></span>
                </p>

                <p className="ml-3 text-[#bfcd2a] text-[.9em] text-left py-3 font-semibold overflow-hidden flex whitespace-nowrap">
                  Material:{' '}
                  <span className="text-white font-light ml-2">
                    {props.data.material}
                  </span>
                </p>
              </div>
            </div>
    
      </div>
  );
}
