export const partners = [
  {
    partnerName: 'unicef',
    partnerAlt: 'unicef logo',
  },
  {
    partnerName: 'cup',
    partnerAlt: 'cup logo',
  },
  {
    partnerName: 'jsi',
    partnerAlt: 'jsi logo',
  },
  {
    partnerName: 'msf',
    partnerAlt: 'msf logo',
  },
  {
    partnerName: 'unhabitat',
    partnerAlt: 'unhabitat logo',
  },
  {
    partnerName: 'unhcr',
    partnerAlt: 'unicef logo',
  },
  {
    partnerName: 'whh',
    partnerAlt: 'whh logo',
  },
  {
    partnerName: 'adh',
    partnerAlt: 'adh logo',
  },
  {
    partnerName: 'daanish',
    partnerAlt: 'daanish logo',
  },
  {
    partnerName: 'ifrc',
    partnerAlt: 'ifrc logo',
  },
  {
    partnerName: 'iico',
    partnerAlt: 'iico logo',
  },
  {
    partnerName: 'lpp',
    partnerAlt: 'lpp logo',
  },
  {
    partnerName: 'ndma',
    partnerAlt: 'ndma logo',
  },
  {
    partnerName: 'un',
    partnerAlt: 'un logo',
  },
  {
    partnerName: 'undp',
    partnerAlt: 'undp logo',
  },
  {
    partnerName: 'unfpa',
    partnerAlt: 'unfpa logo',
  },
];
