import React from 'react';
import { RiTeamFill } from 'react-icons/ri';
 import {BsFillPersonCheckFill} from 'react-icons/bs';
 import {HiBadgeCheck} from 'react-icons/hi';

export function Values() {
  return (
    <div className="flex my-40 items-center">
      <div className="w-[35%] h-[100%]">
        <h1 className="text-center text-[#5a5a5a] text-[2em] py-10 font-thin border-r-[1px] border-[#66941E] ">
          Our core success lies in our <br />{' '}
          <span className="text-[#5a5a5a] text-[2.5em] capitalize font-light">
            values & principles
          </span>
        </h1>
      </div>

      <div className="w-[65%] flex justify-around ">
       
       {/* Teamwork */}
        <div className="bg-[#5a5a5a] h-[200px] w-[200px] rounded-full justify-center text-center flex flex-col text-white tracking-wide px-4 cursor-pointer hover:scale-[1.1] transition-all duration-500 ease">
          <RiTeamFill className="w-full text-[2em]" />
          <h4 className="uppercase text-sm font-bold text-[#b3e16a]">Teamwork</h4>
          <p className="font-thin text-[12px] mt-2 px-[6px]">
            We strongly believe in teamwork
          </p>
        </div>

         {/* Quality */}
         <div className="bg-[#5a5a5a] h-[200px] w-[200px] rounded-full justify-center text-center flex flex-col text-white tracking-wide px-4  cursor-pointer hover:scale-[1.1] transition-all duration-500 ease">
          <HiBadgeCheck className="w-full text-[2em]" />
          <h4 className="uppercase text-sm font-bold  text-[#b3e16a]">Quality</h4>
          <p className="font-thin text-[12px] mt-2 px-[6px]">
            We strive to produce high quality end results
          </p>
        </div>

         {/* Teamwork */}
         <div className="bg-[#5a5a5a] h-[200px] w-[200px] rounded-full justify-center text-center flex flex-col text-white tracking-wide px-4 cursor-pointer hover:scale-[1.1] transition-all duration-500 ease">
          <BsFillPersonCheckFill className="w-full text-[2em] " />
          <h4 className="uppercase text-sm font-bold text-[#b3e16a]">Integrity</h4>
          <p className="font-thin text-[12px] mt-2 px-[6px]">
            We beleive in strong core eithics
          </p>
        </div>

      </div>
    </div>
  );
}
