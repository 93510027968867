import React from 'react'
import {HiOutlineClipboardCheck} from 'react-icons/hi'
import {MdChangeCircle} from 'react-icons/md'
import {BsCartCheckFill} from 'react-icons/bs'
import {TbTruckDelivery} from 'react-icons/tb'



export function Process() {
  return (

     <div className="text-center  my-10 py-20">
      <div className="text-center text-[#5a5a5a] text-[2.5em] py-5 font-semibold">
       How we do it <span className='text-[#66941E] text-[1.5em]'>...</span>
      </div>
      <div className="bg-[#66941E] h-[1px]   mx-[48%] mt-[-15px]">
      </div>

      <div className=" flex justify-around mx-[15%] mt-20">
        <div className='w-[15%] rounded-lg group cursor-pointer'>
          <HiOutlineClipboardCheck className='text-center w-full text-[2em] text-[#405224]' />

          <h1 className='text-[1.2em] bg-white font-light border-b-[1px] py-2 border-[#66941E]'>Requirements</h1>
          <p className='font-light pt-2 px-3 align-middle  text-[0.9em] h-[0%] group-hover:h-[100%] transition-all duration-500 ease-in-out overflow-hidden bg-gray-50 z-[-1]'>Once you share details of required product we shall provide you with the most suitable quote</p>
        </div>
        
        <div className='w-[15%] rounded-lg group cursor-pointer'>
          <BsCartCheckFill className='text-center w-full text-[2em] text-[#405224]' />

          <h1 className='text-[1.2em] bg-white font-light border-b-[1px] py-2 border-[#66941E]'>Order</h1>
          <p className='font-light pt-2 px-3 align-middle  text-[0.9em] h-[0%] group-hover:h-[100%] transition-all duration-500 ease-in-out overflow-hidden bg-gray-50'>We initiate our process after confirmation of purchase order</p>
        </div>

        <div className='w-[15%] rounded-lg group cursor-pointer'>
          <MdChangeCircle className='text-center w-full text-[2em] text-[#405224]' />

          <h1 className='text-[1.2em] bg-white font-light border-b-[1px] py-2 border-[#66941E]'>Processing</h1>
          <p className='font-light pt-2 px-3 align-middle  text-[0.9em] h-[0%] group-hover:h-[100%] transition-all duration-500 ease-in-out overflow-hidden bg-gray-50'>Manufacturing takes place within the agreed timeline</p>
        </div>
        
        <div className='w-[15%] rounded-lg group cursor-pointer'>
          <TbTruckDelivery className='text-center w-full text-[2em] text-[#405224]' />

          <h1 className='text-[1.2em] bg-white font-light border-b-[1px] py-2 border-[#66941E]'>Delivery</h1>
          <p className='font-light pt-2 px-3 align-middle  text-[0.9em] h-[0%] group-hover:h-[100%] transition-all duration-500 ease-in-out overflow-hidden bg-gray-50'> Then supply of agreed deliverables is dispatched to mentioned address </p>
        </div>
        

      </div>
    </div>
        
  )
}
