import React, { useEffect, useState } from 'react';
import { Product } from './Product';
import { Link, useNavigate } from 'react-router-dom';

export default function ProductList(props) {
  const [filteredArray, setFilteredArray] = useState(null);
  const [currCategory, setCurrCategory] = useState('All');
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/products/all');

    setFilteredArray(props.productData.products);
  }, [props]);

  const handleCategory = (c) => {
    setCurrCategory(c);
    const newFilterArray = props.productData.products.filter((d) => {
      if (c === 'All') {
        return d;
      } else {
        return d.category.name === c;
      }
    });
    setFilteredArray(newFilterArray);
  };
 
  const handleProductDetails = (data)=> {
     navigate(`/products/product-details/${data.category.slug}/${data.slugProductName}/${data.id}`,{state: {data}})
  }

  return (
    <div>

      <div
        className="bg-cover bg-center w-full h-[30em] relative "
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("https://cdn.pixabay.com/photo/2017/06/14/08/20/map-of-the-world-2401458_1280.jpg")`,
        }}>
        <div className=" absolute top-[50%] left-1/2 translate-x-[-50%] translate-y-[-50%]   tracking-wider ml-10 font-thin flex">
          {props.productData.categories?.map((data) => {
            return (
              <Link to={`/products/${data.slug}`}>
                <div
                  className={` rounded-full p-8 h-[8em] w-[8em] cursor-pointer mx-2 flex justify-center items-center text-center ${
                    currCategory === data.name
                      ? 'bg-black text-white border-[2px] border-[#66941E]'
                      : 'bg-white'
                  } hover:bg-black hover:text-white border-[2px] hover:border-[#66941E]`}
                  onClick={() => handleCategory(data.name)}>
                  <span className="text-[1.1em] ">{data.name}</span>
                </div>
              </Link>
            );
          })}
        </div>
      </div>

      <div className="py-20 flex justify-center flex-wrap mx-[10%] " >
        {filteredArray && filteredArray.length > 0 ? 
          filteredArray.map((data) => {
            return(
              <div className='' onClick={()=> handleProductDetails (data)}>
          <Product  data={data}  /> 
          
          </div>)
          }) : (
          <p className="text-black text-[1.2em] mx-[10%] font-light text-center py-20">
            No product found....
          </p>
        )}
      </div>
    </div>
  );
}
