import React from 'react';
import {
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
} from 'react-icons/fa';
import { BsFillTelephoneFill } from 'react-icons/bs';


export const TopNav = () => {
  return (
    <div className="flex bg-black py-2">
      <div className="flex items-center ml-10 cursor-pointer">
        <BsFillTelephoneFill className="fill-[#66941E] text-sm" />
        <p className="text-sm ml-5 font-bold text-white ] tracking-widest">
          UAN: (+92)-51-2555654
        </p>
      </div>
      <div className="flex mr-10 ml-auto py-2 justify-end text-white">
       <a href='https://www.facebook.com/profile.php?id=100064361221453' target='_blank'>   <FaFacebookF className=" hover:fill-[#66941E] mx-2 cursor-pointer" /> </a> 
       <a href='https://www.linkedin.com/company/pak-business-international-pvt-ltd-' target='_blank'>   <FaLinkedinIn className="hover:fill-[#66941E] mx-2 cursor-pointer" /> </a>
        <FaTwitter className="hover:fill-[#66941E] mx-2 cursor-pointer" />
        <FaInstagram className="hover:fill-[#66941E] mx-2 cursor-pointer" />
      </div>
    </div>
  );
};
