export const truckImages = [
  { name: 'Dumper Truck', image: 'dumper.png', alt: 'dumper' },
  { name: 'Heavy Duty Truck', image: 'heavy-truck.png', alt: 'heavy truck' },
  { name: 'Cargo Mover', image: 'mover.png', alt: 'mover truck' },
  { name: 'Oil Tanker', image: 'oil-tanker.png', alt: 'oil tanker' },
  { name: 'Long Oil Tanker', image: 'tanker.png', alt: 'tanker' },
  {
    name: 'Transport Truck',
    image: 'transport-truck.png',
    alt: 'transport truck',
  },
];



export const earthMoving = [
  { name: 'Crawler Crane', image: 'crawler-crane.png', alt: 'Crawler Crane' },
  { name: 'Motorized Dozer', image: 'motor-dozer.png', alt: 'Motorized Dozer' },
  { name: 'Shovel Crane', image: 'shovel.png', alt: 'Shovel Crane' },
  { name: 'Crane', image: 'crane.png', alt: 'Crane' },
  { name: 'Bulldozer', image: 'bull-dozer.png', alt: 'Bulldozer' },
  {
    name: 'Dozer 4x6',
    image: 'dozer46.png',
    alt: 'Dozer 4x6',
  },
]


export const powerSupply = [
  { name: 'Standby Power', image: 'standby.png', alt: 'standby generator' },
  { name: 'Prime Power', image: 'prime.png', alt: 'prime power supply' }
]
